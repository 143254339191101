var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"add-edit-utilizator history-modal",on:{"mousedown":_vm._clickModal}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.nume),expression:"data.nume"}],staticClass:"mtop12px capitalize",class:{ 'invalid-input': errors[0] },attrs:{"type":"text","placeholder":"nume utilizator"},domProps:{"value":(_vm.data.nume)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "nume", $event.target.value)}}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.email),expression:"data.email"}],staticClass:"mtop12px",class:{ 'invalid-input': errors[0] },attrs:{"type":"text","autocomplete":"n123mnn123","disabled":_vm.isUpdateModal,"placeholder":"email utilizator"},domProps:{"value":(_vm.data.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "email", $event.target.value)}}}),(_vm.isExistingUser)?_c('span',[_vm._v("email existent în baza de date")]):_vm._e()]}}],null,true)}),(!_vm.isUpdateModal)?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.password),expression:"data.password"}],staticClass:"mtop12px",class:{ 'invalid-input': errors[0] },attrs:{"type":"text","placeholder":"parolă"},domProps:{"value":(_vm.data.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "password", $event.target.value)}}})]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('####-###-###'),expression:"'####-###-###'"},{name:"model",rawName:"v-model",value:(_vm.data.telefon),expression:"data.telefon"}],ref:"telefon",staticClass:"mtop12px",class:{ 'invalid-input': errors[0] },attrs:{"type":"text","placeholder":"telefon utilizator"},domProps:{"value":(_vm.data.telefon)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "telefon", $event.target.value)}}})]}}],null,true)}),_c('div',{staticClass:"mtop12px relative"},[_c('drop-down',{attrs:{"data":_vm.ddRoluri,"value":_vm.data.rol},on:{"selected":_vm._setDDvalue}})],1),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.functie),expression:"data.functie"}],staticClass:"mtop12px",class:{ 'invalid-input': errors[0] },attrs:{"disabled":_vm.$store.state.user.rol === 'medic',"type":"text","placeholder":"funcție utilizator"},domProps:{"value":(_vm.data.functie)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "functie", $event.target.value)}}})]}}],null,true)}),(_vm.isUpdateModal && _vm.user.email === _vm.data.email)?_c('div',[_c('div',{staticClass:"mtop12px"},[_c('validation-provider',{attrs:{"rules":"password:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.password),expression:"data.password"}],class:{ 'invalid-input': errors[0] },attrs:{"type":"text","autocomplete":"198sd8as8d823121","placeholder":"parolă nouă"},domProps:{"value":(_vm.data.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "password", $event.target.value)}}})]}}],null,true)})],1),_c('div',{staticClass:"mtop12px"},[_c('validation-provider',{attrs:{"vid":"confirm","name":"confirm","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirm),expression:"confirm"}],class:{ 'invalid-input': errors[0] },attrs:{"type":"text","autocomplete":"asdasd090a9ds","placeholder":"repeta parola"},domProps:{"value":(_vm.confirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirm=$event.target.value}}})]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"flex"},[(
            _vm.data._id &&
            _vm.user.rol.includes('admin') &&
            !_vm.data.rol.includes('admin')
          )?_c('delete-tratament-btn',{on:{"delete-tratament":_vm.deleteUser,"set-delete-mode":_vm._checkDelete}}):_vm._e(),_c('button',{staticClass:"submit flex-center submit-active m-left-auto"},[_c('i',{domProps:{"innerHTML":_vm._s(_vm.svg.save)}})])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }