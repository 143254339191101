<template>
  <section class="add-edit-utilizator history-modal" @mousedown="_clickModal">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <validation-provider rules="required" v-slot="{ errors }">
          <input
            class="mtop12px capitalize"
            :class="{ 'invalid-input': errors[0] }"
            type="text"
            v-model="data.nume"
            placeholder="nume utilizator"
          />
        </validation-provider>

        <validation-provider rules="required|email" v-slot="{ errors }">
          <input
            class="mtop12px"
            :class="{ 'invalid-input': errors[0] }"
            type="text"
            autocomplete="n123mnn123"
            :disabled="isUpdateModal"
            v-model="data.email"
            placeholder="email utilizator"
          />

          <span v-if="isExistingUser">email existent în baza de date</span>
        </validation-provider>

        <validation-provider
          v-if="!isUpdateModal"
          rules="required"
          v-slot="{ errors }"
        >
          <input
            class="mtop12px"
            :class="{ 'invalid-input': errors[0] }"
            type="text"
            v-model="data.password"
            placeholder="parolă"
          />
        </validation-provider>

        <!-- TELEFON -->
        <validation-provider rules="" v-slot="{ errors }">
          <input
            class="mtop12px"
            :class="{ 'invalid-input': errors[0] }"
            type="text"
            ref="telefon"
            v-mask="'####-###-###'"
            v-model="data.telefon"
            placeholder="telefon utilizator"
          />
        </validation-provider>

        <!--  ROLURI -->
        <div class="mtop12px relative">
          <drop-down
            :data="ddRoluri"
            :value="data.rol"
            @selected="_setDDvalue"
          />
        </div>

        <!--  FUNCTIE -->
        <validation-provider rules="required" v-slot="{ errors }">
          <input
            class="mtop12px"
            :class="{ 'invalid-input': errors[0] }"
            :disabled="$store.state.user.rol === 'medic'"
            type="text"
            v-model="data.functie"
            placeholder="funcție utilizator"
          />
        </validation-provider>

        <!-- CHANGE PASS -->
        <div v-if="isUpdateModal && user.email === data.email">
          <div class="mtop12px">
            <validation-provider rules="password:@confirm" v-slot="{ errors }">
              <input
                type="text"
                :class="{ 'invalid-input': errors[0] }"
                v-model="data.password"
                autocomplete="198sd8as8d823121"
                placeholder="parolă nouă"
              />
            </validation-provider>
          </div>

          <div class="mtop12px">
            <validation-provider
              vid="confirm"
              name="confirm"
              rules=""
              v-slot="{ errors }"
            >
              <input
                type="text"
                :class="{ 'invalid-input': errors[0] }"
                autocomplete="asdasd090a9ds"
                v-model="confirm"
                placeholder="repeta parola"
              />
            </validation-provider>
          </div>
        </div>

        <div class="flex">
          <delete-tratament-btn
            v-if="
              data._id &&
              user.rol.includes('admin') &&
              !data.rol.includes('admin')
            "
            @delete-tratament="deleteUser"
            @set-delete-mode="_checkDelete"
          />

          <button class="submit flex-center submit-active m-left-auto">
            <i v-html="svg.save"></i>
          </button>
        </div>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
  import { mapState } from 'vuex';
  import { historyModal } from '../mixins/history-modal';
  export default {
    name: 'add-edit-utilizator',
    mixins: [historyModal],

    data () {
      return {
        ddRoluri: {
          name: 'rol',
          placeholder: 'rol',
          ddData: 'roluri',
          validation: true,
          disabled: !this.$store.state.user.rol.includes('admin')
        },
        submitted: false,
        isDelete: false,
        telMask: undefined,
        new_pass: undefined,
        confirm: undefined
      };
    },

    created () {
      this.$store.commit('SET_KEY_VALUE', {
        key: 'isExistingUser',
        value: undefined
      });
    },

    computed: {
      ...mapState({
        data: 'modalData',
        isUpdateModal: 'isUpdateModal',
        svg: 'svg',
        isExistingUser: 'isExistingUser',
        user: (state) => state.user
      })
    },

    methods: {
      deleteUser () {
        this.isDelete = true;
        this.$store.dispatch('delete_user');
      },
      setPic (field) {
        return this.data.pics.filter((obj) => obj.name.indexOf(field) > -1)[0];
      },
      selectImg ({ file, picName }) {
        this.$store.commit('ADD_IMGS_TO_MODAL', { file, picName });
      },
      submit () {
        !this.isDelete &&
          this.$store.dispatch(
            this.isUpdateModal ? 'update_utilizator' : 'save_utilizator'
          );
      }
    }
  };
</script>

<style lang="less">
  @import "../assets/styles/mixin";
  @import "../assets/styles/var";
  .add-edit-utilizator {
    input + span {
      font-size: 1.4rem;
      color: @red;
      display: inline-block;
      padding: 6px 0 0 4px;
    }

    .delete-user {
      background-color: @red;
      &:hover {
        background-color: darken(@red, 20%, relative) !important;
      }
    }

    .submit,
    .actions {
      margin-top: 20px;
    }

    .delete-tratament-btn button {
      margin-top: 16px;
    }
  }
</style>
